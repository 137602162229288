import { Controller } from 'stimulus'

class TypesController extends Controller {
  public listingTarget: HTMLElement

  public errorTarget: HTMLElement

  public fieldsTarget: HTMLElement

  public urlValue: string
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['listing', 'error', 'fields']

  static values = {
    url: String,
  }

  public checkStatusInterval: NodeJS.Timeout

  connect() {}

  handleSuccess(event: any) {
    const [, , xhr]: [HTMLDocument, string, XMLHttpRequest] = event.detail

    const res = JSON.parse(xhr.response)

    this.renderReportListing(this.listingTarget, res.job_id)
    this.checkStatusInterval = setInterval(() => this.fetchStatus(res.job_id), 5000)

    this.errorTarget.textContent = ''
  }

  handleError(event: any) {
    const [, , xhr]: [HTMLDocument, string, XMLHttpRequest] = event.detail

    const res = JSON.parse(xhr.response)

    this.errorTarget.textContent = res.error
  }

  updateFields() {
    const formData = new FormData(this.element as HTMLFormElement)
    const queryString = new URLSearchParams(formData as any).toString()
    console.log(queryString)
    fetch(`${this.urlValue}?${queryString}`)
      .then((res) => res.text())
      .then((html) => {
        this.fieldsTarget.innerHTML = html
      })
      .catch((err) => console.error(err))
  }

  renderReportListing(targetElement: Element, jobId: string | null) {
    fetch(`/reports/report_listing?${new URLSearchParams(jobId ? { job_id: jobId } : {})}`)
      .then((res) => res.text())
      .then((html) => {
        targetElement.innerHTML = html
      })
      .catch((err) => console.error(err))
  }

  fetchStatus(jobId: string) {
    fetch(`/reports/check_job_status?${new URLSearchParams({ job_id: jobId })}`)
      .then((res) => res.json())
      .then((obj: { done: boolean }) => {
        if (obj.done) {
          clearInterval(this.checkStatusInterval)
          this.renderReportListing(this.listingTarget, null)
        }
      })
      .catch((err) => console.error(err))
  }
}
