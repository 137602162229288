import { Controller } from 'stimulus'

class TypesController extends Controller {
  public inputTarget: HTMLInputElement
  public displayTarget: HTMLElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['display', 'input']

  connect() {
    this.conditionallyDisplaySection()
  }

  conditionallyDisplaySection() {
    if (this.inputTarget.value && this.inputTarget.value !== 'archive') {
      this.displayTarget.style.display = 'block'
    } else {
      this.displayTarget.style.display = 'none'
    }
  }
}
