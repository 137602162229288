import { parseISO } from 'date-fns'
import M from 'materialize-css'
import FormController from './form_controller'
import { slashedDateToISODate } from '../helpers'

class TypesController extends FormController {
  errorTarget: HTMLElement
  modalTarget: HTMLElement
  timestampsValue: string[][]
}

export default class extends (FormController as typeof TypesController) {
  static targets = ['error', 'modal']
  static values = { timestamps: Array }

  error(event: any) {
    const [, , xhr]: [HTMLDocument, string, XMLHttpRequest] = event.detail
    this.errorTarget.innerHTML = xhr.response
  }

  checkForOverlap(event: Event) {
    const date = (this.element.querySelector('#call_date_part') as HTMLInputElement).value
    const startsAt = (this.element.querySelector('#call_starts_at_part') as HTMLInputElement).value
    const endsAt = (this.element.querySelector('#call_ends_at_part') as HTMLInputElement).value
    const tz = document.getElementById('page-data')?.dataset.patientTzOffset

    const callStart = parseISO(`${slashedDateToISODate(date)}T${startsAt}${tz}`)
    const callEnd = parseISO(`${slashedDateToISODate(date)}T${endsAt}${tz}`)

    const isOverlapping = this.timestampsValue.some(
      (interval: string[]) =>
        parseISO(interval[1]).getTime() >= callStart.getTime() && parseISO(interval[0]).getTime() <= callEnd.getTime(),
    )

    if (isOverlapping) {
      const modal = M.Modal.getInstance(this.modalTarget)
      if (!modal.isOpen) {
        modal.open()
        event.preventDefault()
      } else {
        modal.close()
      }
    }
  }

  hostChange(event: Event) {
    const target = event.target as HTMLInputElement
    const userId = target.value

    fetch(`/users/${userId}/scheduled_calls`)
      .then((response) => response.json())
      .then((data) => {
        this.data.set('timestamps', JSON.stringify(data.timestamps))
      })
      .catch((error) => {
        console.error(error)
        this.errorTarget.innerHTML = 'An error has occurred. Please try again!'
      })
  }
}
