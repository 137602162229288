import { Controller } from 'stimulus'
import M from 'materialize-css'

class TypesController extends Controller {
  public selectsTarget: HTMLElement
  public currentValue: string
  public programIdValue: string
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['selects']
  static values = { current: String, programId: String }

  providers: { name: string; id: number }[]

  connect() {
    this.loadNewProviders(null)
  }

  loadNewProviders(event: Event | null) {
    const input = (event?.target || document.querySelector('select[name="patient_program[program_id]"]')) as HTMLSelectElement | null
    const attributeName = (this.selectsTarget.querySelectorAll('select')[0] as HTMLSelectElement)?.name

    if (!attributeName) return

    const programId = input?.value || this.programIdValue

    if (!programId) return

    Array.from(this.selectsTarget.children).forEach((select) => {
      select.remove()
    })
    
    fetch(`/programs/${programId}/providers?attribute_name=${attributeName}&selected=${this.currentValue}`)
      .then((res) => res.text())
      .then((html) => {
        this.selectsTarget.insertAdjacentHTML('beforeend', html)
        M.FormSelect.init(this.selectsTarget.querySelectorAll('select'))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
