import { parseISO } from 'date-fns'
import FormController from './form_controller'
import MapsController from './maps_controller'

class TypesController extends FormController {
  titleTarget: HTMLInputElement

  startTarget: HTMLInputElement

  endTarget: HTMLInputElement

  placeTarget: HTMLInputElement

  detailsTarget: HTMLInputElement

  categoryTarget: HTMLInputElement

  locationTarget: HTMLInputElement

  favoritesTarget: HTMLElement

  mapTarget: HTMLElement

  locationsTarget: HTMLElement
}

export default class extends (FormController as typeof TypesController) {
  static targets = [
    'title',
    'start',
    'end',
    'place',
    'details',
    'category',
    'location',
    'favorites',
    'map',
    'locations',
  ]

  applyLocation(event: Event) {
    const target = event.currentTarget as HTMLElement

    const locationObject = target.dataset.googlePlaceObj
    this.placeTarget.value = locationObject || ''

    const parsedObject = JSON.parse(locationObject || '{}')
    this.locationTarget.value = parsedObject.name
    this.mapsController.moveMarker(
      new google.maps.LatLng(parsedObject.geometry.location.lat, parsedObject.geometry.location.lng),
    )

    this.toggleLocationDisplay()
  }

  toggleLocationDisplay(event?: Event) {
    const isKeyupEvent = event?.type === 'keyup'

    Array.from(this.locationsTarget.children).forEach((child: HTMLElement) => {
      if (child.classList.contains('hide') && !isKeyupEvent) {
        child.classList.remove('hide')
      } else {
        child.classList.add('hide')
      }
    })
  }

  filterFavorites(event: Event) {
    const { value } = event.target as HTMLInputElement

    Array.from(this.favoritesTarget.children).forEach((child: HTMLElement) => {
      if (child.dataset.title?.toUpperCase().includes(value.toUpperCase())) {
        child.classList.remove('hide')
      } else {
        child.classList.add('hide')
      }
    })
  }

  applyFavorite(event: Event) {
    const target = event.currentTarget as HTMLElement

    const { googlePlaceObj, title, startsAt, endsAt, details, appointmentCategoryId } = target.dataset

    this.titleTarget.value = title || ''
    this.startTarget.value = startsAt || ''
    this.endTarget.value = endsAt || ''
    this.placeTarget.value = googlePlaceObj || ''
    this.detailsTarget.value = details || ''
    this.categoryTarget.value = appointmentCategoryId || ''

    const parsedObject = JSON.parse(googlePlaceObj || '')

    if (parsedObject && parsedObject.geometry) {
      this.locationTarget.value = parsedObject.name
      this.mapsController.moveMarker(
        new google.maps.LatLng(parsedObject.geometry.location.lat, parsedObject.geometry.location.lng),
      )
    } else {
      this.locationTarget.value = ''
    }

    Array.from(this.locationsTarget.children).forEach((child: HTMLElement) => child.classList.add('hide'))
  }

  get mapsController() {
    return this.application.getControllerForElementAndIdentifier(this.mapTarget, 'maps') as MapsController
  }
}
