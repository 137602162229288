import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const state = this.initialState()
    if (state != 'standard') this.disableAllAutoSwitchPrograms()
    this.setAutoSwitchPrograms(state)
    this.display(state)
  }

  update(event) {
    const state = this.stateFrom(event)
    this.set(state)
    this.setRewardServices(state)
    this.setAutoSwitchPrograms(state)
    this.display(state)
  }

  initialState() {
    return (this.element.querySelector('input[name="program[type]"][checked="checked"]') as HTMLInputElement)?.value
  }

  stateFrom(event) {
    return event.target.parentElement.querySelector('input').value
  }

  set(state) {
    (this.element.querySelector('input[name="program[type]"][type="hidden"]') as HTMLInputElement).value = state
  }

  display(state) {
    const appBasedElements = this.element.querySelectorAll('.app-based')
    const appLessElements = this.element.querySelectorAll('.app-less')

    appBasedElements.forEach((node) => {
      state == 'standard' ? this.enable(node) : this.disable(node)
    })

    appLessElements.forEach((node) => {
      state == 'standard' ? this.disable(node) : this.enable(node)
    })
  }

  enable(node) {
    node.classList.remove('disabled')
    node.querySelectorAll('input').forEach((i) => { i.disabled = false })
  }

  disable(node) {
    node.classList.add('disabled')
    node.querySelectorAll('input').forEach((i) => { i.disabled = true })
  }

  setRewardServices(state) {
    // state == 'standard' ? this.enableAllRewardService() : this.disableAllRewardService()
  }

  setAutoSwitchPrograms(state) {
    state == 'standard' ? this.enableAllAutoSwitchPrograms() : this.disableAllAutoSwitchPrograms()
  }

  rewardServiceOptions() {
    return this.rewardServiceSelect()?.querySelectorAll('option')
  }

  rewardServiceSelect() {
    return this.element.querySelector('#program_reward_service_type') as HTMLSelectElement
  }

  autoSwitchSelect() {
    return this.element.querySelector('#program_auto_switch_select') as HTMLSelectElement
  }

  rewardServiceList() {
    return this.element.querySelectorAll('#reward-selector ul.select-dropdown li')
  }

  rewardServiceInput() {
    return this.element.querySelector('#reward-selector input.dropdown-trigger') as HTMLInputElement
  }

  enableAllRewardService() {
    const rewardServiceList = this.rewardServiceList()
    this.rewardServiceOptions().forEach((o, i) => {
      o.disabled = false
      rewardServiceList[i].classList.remove('disabled')
      o.value == 'DefaultRewardService' ? rewardServiceList[i].classList.add('selected') : rewardServiceList[i].classList.remove('selected')
    })
    this.rewardServiceSelect()!.value = 'DefaultRewardService'
    this.rewardServiceInput()!.value = 'Default Prize Rewards'
    this.getCurrentRewardDescription('DefaultRewardService')
  }

  disableAllRewardService() {
    const rewardServiceList = this.rewardServiceList()
    this.rewardServiceOptions().forEach((o, i) => {
      o.disabled = o.value == 'ManualRewardService' ? false : true
      o.value == 'ManualRewardService' ? rewardServiceList[i].classList.remove('disabled') : rewardServiceList[i].classList.add('disabled')
      o.value == 'ManualRewardService' ? rewardServiceList[i].classList.add('selected') : rewardServiceList[i].classList.remove('selected')
    })
    this.rewardServiceSelect().value = 'ManualRewardService'
    this.rewardServiceInput().value = 'Manual Rewards'
    this.getCurrentRewardDescription('ManualRewardService')
  }

  enableAllAutoSwitchPrograms() {
    this.autoSwitchSelect()!.querySelectorAll('option')!.forEach(o => {
      o.classList.remove('hide')
    });
  }

  disableAllAutoSwitchPrograms() {
    this.autoSwitchSelect()!.querySelectorAll('option')!.forEach(o => {
      if (o.value !== 'archive') o.classList.add('hide')
      else {
        o.classList.remove('hide')
        this.autoSwitchSelect().value = this.autoSwitchSelect().value == 'archive' ? 'archive' : ''
      }

    });
  }

  getCurrentRewardDescription(value) {
    $.ajax({
      url: '/programs/reward-service-description/' + value,
      type: 'GET',
      dataType: 'script'
    });
  }
}
