import { Controller } from 'stimulus'
import feather from 'feather-icons'

class TypesController extends Controller {
  public contentTarget!: HTMLElement

  public iconTarget!: HTMLElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['content', 'icon']

  toggle() {
    const element = this.contentTarget

    if (element.style.maxHeight === '0px') {
      element.style.maxHeight = '1000px'
      this.iconTarget.innerHTML = feather.icons['chevron-up'].toSvg()
    } else {
      element.style.maxHeight = '0px'
      this.iconTarget.innerHTML = feather.icons['chevron-down'].toSvg()
    }
  }
}
