import { Controller } from 'stimulus'
import M from 'materialize-css'
import { parseISO } from 'date-fns'

class TypesController extends Controller {
  public fieldTarget!: HTMLInputElement
  public optionsValue: {
    defaultDate: string;
    minDate: string;
    maxDate: string;
    format: string;
  }
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['field']
  static values = { options: Object }

  connect() {
    const { defaultDate, minDate, maxDate, format } = this.optionsValue

    M.Datepicker.init(this.fieldTarget, {
      autoClose: true,
      setDefaultDate: true,
      defaultDate: defaultDate ? parseISO(defaultDate) : new Date(),
      format: format || 'mm/dd/yyyy',
      container: document.body,
      minDate: parseISO(minDate),
      maxDate: parseISO(maxDate),
    })
  }
}
