import { Controller } from 'stimulus'

class TypesController extends Controller {
  public checkerTargets: HTMLInputElement[];

  public selectTarget: HTMLSelectElement;
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['checker', 'select']

  updateSelect() {
    if (this.checkerTargets.filter(target => target.checked == true).length > 0) this.selectTarget.disabled = false;
    else this.selectTarget.disabled = true;
  }
}
