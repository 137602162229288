import { Controller } from 'stimulus'

class TypesController extends Controller {
  public searchTarget!: HTMLInputElement

  public inputTarget!: HTMLInputElement

  public mapTarget!: HTMLElement

  public errorTarget!: HTMLElement
}

export default class extends (Controller as typeof TypesController) {
  autocomplete: google.maps.places.Autocomplete

  marker: google.maps.Marker

  circle: google.maps.Circle

  map: google.maps.Map

  static targets = ['input', 'map', 'error', 'search']

  connect() {
    if (typeof google !== 'undefined') {
      this.initMap()
    }
  }

  initMap() {
    const input = this.searchTarget

    this.autocomplete = new google.maps.places.Autocomplete(input, {
      fields: ['place_id', 'name', 'formatted_address', 'geometry'],
    })
    this.autocomplete.addListener('place_changed', this.fillInAddress.bind(this))

    const currentPlace = JSON.parse(this.inputTarget.value)

    const isExistingPlace = currentPlace && Object.keys(currentPlace).length > 0

    const USCENTER = new google.maps.LatLng(37.09024, -95.712891)

    // If there is an existing place, use that. If not, then use the US center.
    const initialCoordinates = isExistingPlace
      ? new google.maps.LatLng(currentPlace.geometry.location.lat, currentPlace.geometry.location.lng)
      : USCENTER

    // Create the map
    this.map = new google.maps.Map(this.mapTarget, {
      center: initialCoordinates,
      zoom: 3,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      gestureHandling: 'cooperative',
      fullscreenControl: false,
      streetViewControl: false,
    })

    this.marker = new google.maps.Marker({
      visible: false,
      position: initialCoordinates,
      title: 'Point A',
      map: this.map,
    })

    this.circle = new google.maps.Circle({
      visible: false,
      strokeColor: '#f59694',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#f59694',
      fillOpacity: 0.35,
      map: this.map,
      radius: 300,
    })

    this.circle.bindTo('center', this.marker, 'position')

    // Set the circle to be visible if zoomed far enough
    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      const zoom = this.map.getZoom()
      this.circle.setVisible(zoom >= 5)
    })

    // If a place already exists in the form, then move the marker and fill the autocomplete
    if (isExistingPlace) {
      this.moveMarker(initialCoordinates)
      this.searchTarget.value = currentPlace.name
    }
  }

  fillInAddress() {
    const { place_id, name, formatted_address, geometry } = this.autocomplete.getPlace()
    const place = { place_id, name, formatted_address, geometry }

    if (!place.geometry) {
      this.errorTarget.innerText = 'Select an address from the dropdown'
      return
    }
    this.errorTarget.innerText = ''

    this.inputTarget.value = JSON.stringify(place)

    this.moveMarker(new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng()))
  }

  moveMarker(latLng: google.maps.LatLng) {
    this.marker.setPosition(latLng)
    this.marker.setOptions({ visible: true })
    this.map.setCenter(latLng)
    this.map.setZoom(15)
  }
}
