import { Controller } from 'stimulus'

class TypeController extends Controller {
  public programIdInputTarget: HTMLInputElement
  public programFundingTarget: HTMLElement
  public newFundingTarget: HTMLElement
  public resetFundingCheckboxTarget: HTMLInputElement

  public currentFundingValue: number
  public mapValue: { [id: number]: string }
}

export default class extends (Controller as typeof TypeController) {
  static targets = [ "programIdInput", "programFunding", "newFunding", "resetFundingCheckbox" ]
  static values = { currentFunding: Number, map: Object }

  connect() {
    this.displayChanges()
  }

  displayChanges() {
    const programId = this.programIdInputTarget.value

    const programFunding = Number(this.mapValue[Number(programId)])

    this.programFundingTarget.innerText = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(programFunding)
    this.newFundingTarget.innerText = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.resetFundingCheckboxTarget.checked ? programFunding : this.currentFundingValue)
  }
}
