import { Controller } from 'stimulus'

class TypesController extends Controller {}

export default class extends (Controller as typeof TypesController) {
  selected(event: Event) {
    const el = event.target as HTMLInputElement

    if (el.checked)
      this.element.querySelectorAll('input').forEach((el) => {
        if (event.target !== el) el.checked = false
      })
  }
}
