import { Controller } from 'stimulus'

class TypesController extends Controller {
  public displayTargets: HTMLElement[]

  public inputTarget: HTMLInputElement

  public minMaxTarget?: HTMLElement

  public hasMinMaxTarget: boolean
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['display', 'input', 'minMax']

  connect() {
    const { checked } = this.inputTarget
    this.displayTargets.forEach((target) => this.updateTarget(target, checked))
  }

  updateTarget(target: HTMLElement, value: boolean) {
    if (value) target.classList.remove('hide')
    else target.classList.add('hide')
  }

  setMinMax(enabled: boolean) {
    if (this.hasMinMaxTarget) {
      this.minMaxTarget?.setAttribute('min', enabled ? '1' : '')
      this.minMaxTarget?.setAttribute('max', enabled ? '10' : '')
    }
  }

  changeValue(event: Event) {
    const { checked } = event.target as HTMLInputElement

    this.displayTargets.forEach((target) => this.updateTarget(target, checked))
    this.setMinMax(checked)
  }
}
