import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import feather from 'feather-icons'

class TypesController extends Controller {
  public presetValue: string
  public submitterTarget: HTMLElement
  public inputTarget: HTMLInputElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['submitter', 'input']
  static values = { preset: String }

  showDelta() {
    this.submitterTarget.classList.add('animate-pulse', 'text-yellow-500')
    this.submitterTarget.classList.remove('text-red-500', 'text-green-500')
    if (this.inputTarget.value !== this.presetValue) {
      this.submitterTarget.innerHTML = ''
      this.submitterTarget.insertAdjacentHTML('beforeend', feather.icons.triangle.toSvg({ class: 'hover:text-green-500 cursor-pointer transition' }))
    } else {
      this.submitterTarget.innerHTML = ''
    }
  }

  submit() {
    if (this.inputTarget.value === this.presetValue) return

    this.submitterTarget.classList.add('animate-spin')

    Rails.fire(this.inputTarget.form, 'submit')
  }

  success() {
    this.submitterTarget.classList.remove('animate-spin')
    this.submitterTarget.innerHTML = ''
    this.submitterTarget.insertAdjacentHTML('beforeend', feather.icons.check.toSvg({ class: 'text-green-500 fade' }))

    setTimeout(() => {
      this.submitterTarget.innerHTML = ''
    }, 500)
  }

  error(event: any) {
    const [, , xhr]: [HTMLDocument, string, XMLHttpRequest] = event.detail

    const get = (p: any[], o: object) =>
      p.reduce((xs, x) => (xs && xs[x])
    ? xs[x] : null, o)

    const res = JSON.parse(xhr.response)
    const inputAttribute = Array.from(this.inputTarget.name.matchAll(/\[(\w+)\]/g)).map(([_, p1]) => p1)

    this.submitterTarget.classList.remove('animate-spin', 'animate-pulse', 'text-yellow-500')
    this.submitterTarget.classList.add('text-red-500')
    this.submitterTarget.innerHTML = ''
    this.submitterTarget.insertAdjacentHTML('beforeend', feather.icons.x.toSvg())
    this.submitterTarget.insertAdjacentText('beforeend', get(inputAttribute, res.errors || {}))
  }
}
