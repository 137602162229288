import { Controller } from 'stimulus'

class TypesController extends Controller {
  public shippingTarget: HTMLElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['shipping']

  private categories = ['breathalyzer', 'saliva', 'other']

  connect() {
    this.updateCategories()
  }

  updateCategories() {
    let anySelected = false

    this.categories.forEach((category) => {
      const checkbox = this.element.querySelector<HTMLInputElement>(`#supply_${category}_category_enabled`)
      if (checkbox !== null) {
        if (checkbox.checked) {
          anySelected = true
          this.element.querySelector<HTMLElement>(`#${category}_category`)?.classList.remove('hide')
        } else this.element.querySelector<HTMLElement>(`#${category}_category`)?.classList.add('hide')
      }
    })

    if (anySelected) this.shippingTarget.classList.remove('hide')
    else this.shippingTarget.classList.add('hide')
  }
}
