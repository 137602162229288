import { parseISO } from 'date-fns'
import M from 'materialize-css'
import FormController from './form_controller'
import { slashedDateToISODate } from '../helpers'

class TypesController extends FormController {
  errorTarget: HTMLElement
  modalTarget: HTMLElement
  timestampsValue: string[]
}

export default class extends (FormController as typeof TypesController) {
  static targets = ['error', 'modal']
  static values = { timestamps: Array }

  error(event: any) {
    const [, , xhr]: [HTMLDocument, string, XMLHttpRequest] = event.detail
    this.errorTarget.innerHTML = xhr.response
  }

  checkForOverlap(event: Event) {
    const date = (document.getElementById('substance_test_performed_at_date') as HTMLInputElement).value
    const time = (document.getElementById('substance_test_performed_at_time') as HTMLInputElement).value
    const tz = document.getElementById('page-data')?.dataset.patientTzOffset
    const input = parseISO(`${slashedDateToISODate(date)}T${time}${tz}`)
    const performedTimestamps: Date[] = this.timestampsValue.map((t: string) => parseISO(t))

    if (performedTimestamps.some((stamp) => stamp.getTime() === input.getTime())) {
      const modal = M.Modal.getInstance(this.modalTarget)
      if (!modal.isOpen) {
        modal.open()
        // Stop the form from being submitted
        event.preventDefault()
      } else {
        modal.close()
      }
    }
  }
}
