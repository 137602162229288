import { Controller } from 'stimulus'

class TypesController extends Controller {
  public buttonTarget: HTMLButtonElement

  public checkboxTarget: HTMLInputElement

  public inputTargets: HTMLInputElement[]
}

export default class SupplyAddressFormController extends (Controller as typeof TypesController) {
  static targets = ['button', 'checkbox', 'input']

  private defaults = new Map<string, string>()

  connect() {
    this.buttonTarget.disabled = true
    this.inputTargets.forEach((target) => {
      target.classList.add('grey-text')
      if (!this.defaults.has(target.id)) {
        this.defaults.set(target.id, target.value)
      }
    })
  }

  inputChanged(event) {
    const { target } = event
    const newValue = target.value
    const originalValue = this.defaults.get(target.id)
    if (newValue !== originalValue) target.classList.remove('grey-text')
    else target.classList.add('grey-text')
  }

  updateButton() {
    this.buttonTarget.disabled = !this.checkboxTarget.checked
  }
}
