import { Controller } from 'stimulus'

class TypesController extends Controller {
  public titleTarget: HTMLInputElement;
  public bodyTarget: HTMLTextAreaElement;
  public variablesValue: string[]
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['title', 'body']
  static values = { variables: Array }

  show() {
    const modalElement = document.getElementById("notification-preview")

    if (!modalElement) return

    M.Modal.getInstance(modalElement).open()

    const titleElement = modalElement.querySelector('#title')
    const bodyElement = modalElement.querySelector('#body')

    if (titleElement) titleElement.textContent = this.replaceVariables(this.titleTarget.value)
    if (bodyElement) bodyElement.textContent = this.replaceVariables(this.bodyTarget.value)
  }

  private replaceVariables(str: string): string {
    const variableRegex = new RegExp(this.variablesValue.map(v => `{${v}}`).join('|'), 'g')
    const replacer = (str: string): string => {
      const map: { [key: string] : string } = {
        "{reward}": '$1.00',
        "{due_at}": "8:00 PM",
        "{motivational_opener}": "Each day you get closer to your goals.",
        "{motivational_closer}": "We believe you can do it!",
        "{next_reward}": "$2.00",
        "{extra_reward}": "$3.00",
        "{streak}": "1",
        "{test_type}": "Breathalyzer",
        "{emoji}": "✌",
        "{jpal_congrats}": "Congratulations on passing your test!"
      }

      return map[str] || ""
    }

    str = str.replaceAll(/(?:IFREWARD|IFEXTRAREWARD|IFNONCONTINGENT)\[(.*?)\]/g, (_, p1) => p1)

    return str.replaceAll(variableRegex, replacer)
  }
}
