import { Controller } from 'stimulus'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactTooltip from 'react-tooltip'

class TypesController extends Controller {
  public idValue: string
}

export default class extends (Controller as typeof TypesController) {
  static values = { id: String }

  connect() {
    ReactDOM.render(<ReactTooltip id={this.idValue} effect="solid" />, this.element.querySelector('#tooltip'))
  }
}
