import { Controller } from 'stimulus'
import M from 'materialize-css'

class TypesController extends Controller {
  public modalTarget!: HTMLElement
  public optionsValue: {}
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['modal']
  static values = { options: Object }

  connect() {
    const options = this.optionsValue

    M.Modal.init(this.modalTarget, options)
  }

  open(event: Event) {
    event.preventDefault()

    M.Modal.getInstance(this.modalTarget).open()
  }

  close(event: Event) {
    event.preventDefault()

    const instance = M.Modal.getInstance(this.modalTarget)

    // There is a bug with isOpen right now, so we need to manually close it if it returns false
    if (instance.isOpen) {
      instance.close()
    } else {
      instance.el.classList.remove('open')
      instance.el.setAttribute("style", "")
      this.element.querySelector('.modal-overlay')?.remove()
    }

    this.resetForm()
  }

  closeWithDefault(event: Event) {
    const instance = M.Modal.getInstance(this.modalTarget)
    instance.close()
  }

  resetForm() {
    const form = this.modalTarget.getElementsByTagName('form')[0]

    if (form) form.reset()
  }
}
