import { Controller } from 'stimulus'

class TypesController extends Controller {
  public panelsTarget: HTMLElement
  public equipmentsValue: { [key: string]: string[] }
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['panels']
  static values = { equipments: Object }

  changeValue(event: Event) {
    const { value } = event.target as HTMLSelectElement
    const panels = this.equipmentsValue[value] || []

    Array.from(this.panelsTarget.children).forEach((element: HTMLElement) => {
      const { substance } = element.dataset

      if (substance && panels.includes(substance)) {
        element.style.display = 'block'
        element.querySelectorAll('input').forEach((element) => (element.checked = false))
      } else {
        element.style.display = 'none'
        ;(element.querySelector('input[value="n/a"]') as HTMLInputElement).checked = true
      }
    })

    panels.reverse().forEach((panel: string) => {
      const panelElement = this.panelsTarget.querySelector(`div[data-substance='${panel}']`) as HTMLElement

      this.panelsTarget.insertBefore(panelElement, this.panelsTarget.children[0])
    })
  }
}
