import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import feather from 'feather-icons'

class TypesController extends Controller {}

export default class extends (Controller as typeof TypesController) {
  static targets = []

  submit(event: Event) {
    const input = event.target as HTMLInputElement

    const loadingSVG = feather.icons.loader.toSvg({ class: 'mini-loader' })

    input.parentElement?.insertAdjacentHTML('afterend', loadingSVG)

    Rails.fire(input.form, 'submit')
  }

  success() {
    document.querySelectorAll('.mini-loader').forEach((el) => {
      const parent = el.parentElement

      parent?.insertAdjacentHTML('beforeend', feather.icons.check.toSvg({ class: 'green-text fade' }))
      el.parentNode?.removeChild(el)

      setInterval(() => {
        parent?.querySelectorAll('svg.feather-check').forEach((el2) => el2.parentNode?.removeChild(el2))
      }, 500)
    })
  }
}
