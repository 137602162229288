import { Controller } from 'stimulus'

class TypesController extends Controller {
  public input1Target: HTMLInputElement
  public input2Target: HTMLInputElement
  public displayTarget: HTMLInputElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['input1', 'input2', 'display']

  connect() {
    this.updateDisplay()
  }

  updateDisplay() {
    const input1Value = Number(this.input1Target.value)
    const input2Value = Number(this.input2Target.value)

    if (!isNaN(input1Value) && !isNaN(input2Value)) {
      this.displayTarget.value = (input1Value + input2Value).toFixed(2)
    }
  }
}
