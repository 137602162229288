import { Controller } from 'stimulus'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

class TypesController extends Controller {}

export default class extends (Controller as typeof TypesController) {
  connect() {
    new Plyr(this.element as HTMLElement, {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
      ratio: '16:9',
    })
  }
}
