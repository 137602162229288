/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import mixpanel from 'mixpanel-browser'

interface TrackingContext {
  userId: number
  userFullName: string
  userEmail: string
  userCreatedAt: number
  superAdmin: boolean
  admin: boolean
  programAdmin: boolean
  reviewer: boolean
  patientProvider: boolean
  programProvider: boolean
  programsNames: string[]
  programsIds: number[]
  patientId: number | null
  patientProgramName: string | null
  patientProgramId: number | null
  category: string
  type: string | null
  url: string
  trackerVersion: string
}

const TRACKING_CONTEXT_ENDPOINT = '/tracking-context'
const MIXPANEL_EVENT_NAME = 'page_view'

;((): void => {
  const mixpanelProjectToken = extractMixPanelProjectToken()

  if (!mixpanelProjectToken) {
    return
  }

  // We use beacon messages to send analytics, so the tracking context must already be available at the moment
  // when that happens, so we cache in a variable.
  let currentUserTrackingContext: TrackingContext | undefined

  initMixPanel(mixpanelProjectToken)

  window.addEventListener(
    'load',
    async (): Promise<void> => {
      log('startPageTracking on load')
      await startPageTracking()
    },
  )

  document.addEventListener('visibilitychange', (): void => {
    if (document.visibilityState === 'visible') {
      startMixPanelTimer()
    } else {
      sendMixPanelDataBeacon('visibilitychange')
    }
  })

  async function startPageTracking(): Promise<void> {
    startMixPanelTimer()

    await setTrackingContext()
  }

  // We need to extract the category and the type each time to prevent using stale data due to the turbolinks magic.
  async function setTrackingContext(): Promise<void> {
    const patientId = extractPatientId()
    let url = TRACKING_CONTEXT_ENDPOINT

    if (patientId) {
      const searchParams = new URLSearchParams({ patient_id: patientId.toString() })
      url = `${url}?${searchParams}`
    }

    const trackingContext = await fetch(url)
      .then((response): object => response.json())
      .then((data): TrackingContext => objectKeysToCamelCase(data) as TrackingContext)

    const category = extractTrackingCategory()

    log(`categories local(${category}) context(${trackingContext.category})`)

    // I don't think we even need to the local one anymore.
    if (!category || !trackingContext.category) {
      currentUserTrackingContext = undefined
      return
    }

    const userCreatedAt = Date.parse(trackingContext.userCreatedAt.toString())

    currentUserTrackingContext = {
      ...trackingContext,
      userCreatedAt,
      url: window.location.href,
      trackerVersion: 'mr1',
    }

    const { category: cat, type, url: href } = currentUserTrackingContext
    log('setTrackingContext: ', { category: cat, type, url: href })
  }

  function initMixPanel(projectToken: string): void {
    mixpanel.init(projectToken)
  }

  // time_event restarts the timer for each call
  function startMixPanelTimer(): void {
    mixpanel.time_event(MIXPANEL_EVENT_NAME)
  }

  function sendMixPanelDataBeacon(where: string): void {
    if (!currentUserTrackingContext) {
      log(`beacon from ${where}: no context`)
      return
    }

    log(`beacon from ${where}:`, {
      category: currentUserTrackingContext.category,
      type: currentUserTrackingContext.type,
      url: window.location.href,
    })

    mixpanel.track(MIXPANEL_EVENT_NAME, currentUserTrackingContext, { transport: 'sendBeacon' })
  }

  function extractMixPanelProjectToken(): string | null {
    const projectToken = document.querySelectorAll("meta[name='mixpanel-project-token']")[0].getAttribute('content')

    return projectToken || null
  }

  function extractTrackingCategory(): string | null {
    const trackingCategory = document.getElementById('tracking-meta')?.getAttribute('data-tracking-category')

    return trackingCategory || null
  }

  // It is assumed that all patient-related pages have URLs that start with `/patients/:id/`
  function extractPatientId(): number | null {
    const urlBreakdown = window.location.pathname.split('/').filter(Boolean)

    if (urlBreakdown.length < 2) {
      return null
    }

    const [first, second] = urlBreakdown
    const isPatientsScope = first === 'patients'
    const parsedPatientId = parseInt(second, 10)
    const isCorrectPatientId = parsedPatientId && parsedPatientId.toString().length === second.length

    if (isPatientsScope && isCorrectPatientId) {
      return parsedPatientId
    }

    return null
  }

  function objectKeysToCamelCase(object: object): object {
    return Object.fromEntries(Object.entries(object).map(([k, v]): [string, unknown] => [toCamelCase(k), v]))
  }

  function toCamelCase(snakeCasedString: string): string {
    return snakeCasedString.replace(/([-_][a-z])/gi, (char): string => {
      return char.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  function log(message?: any, ...optionalParams: any[]): void {
    console.log(message, ...optionalParams)
  }
})()
