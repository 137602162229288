import { Controller } from 'stimulus'
import M from 'materialize-css'

class TypesController extends Controller {
  public elementsValue: string
  public messageValue: string
}

export default class extends (Controller as typeof TypesController) {
  static values = { elements: String, message: String }

  connect() {
    const el = this.element as HTMLInputElement

    this.toggleElements(el)
  }

  selected(event: Event) {
    const el = event.target as HTMLInputElement

    this.toggleElements(el)
  }

  toggleElements(el: HTMLInputElement) {
    if (el.checked)
      el.form?.querySelectorAll(this.elementsValue).forEach((el: HTMLInputElement) => {
        el.disabled = true
        el.insertAdjacentHTML('afterend', `<span class="text-red-500 text-sm">${this.messageValue}</span>`)
      })
    else
      el.form?.querySelectorAll(this.elementsValue).forEach((el: HTMLInputElement) => {
        el.disabled = false
        el.parentElement?.querySelector('span')?.remove()
      })

    M.updateTextFields()
  }
}
