import { Controller } from 'stimulus'

class TypesController extends Controller {
  public disclaimerTarget: HTMLElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['disclaimer']

  connect() {
    this.disclaimerTarget.addEventListener('click', () => {
      const fullMessage =
        'The DynamiCare Health platform, including drug testing, GPS tracking, surveys, support tools, and incentives, is designed to encourage choices which, as part of a healthy lifestyle, may help those recovering from substance addictions. Drug tests included in the DynamiCare Health platform are intended to be used to discourage substance use and to help inform healthcare providers. The use of the DynamiCare Health platform should be strictly limited to the foregoing. It should not be used for any other purpose, including, without limitation, for confirming the presence or the absence of any substance (drug of abuse) in a user’s specimen, compliance with any substance abuse or other program, or any medical or other diagnostic procedure. The saliva and breath testing devices used with the DynamiCare Health platform provide only qualitative, preliminary results. (See the instructions included in the test device packaging for more information) Click again to shorten disclaimer.'

      if (this.disclaimerTarget.innerHTML !== fullMessage) {
        this.disclaimerTarget.innerHTML = fullMessage
      } else {
        this.disclaimerTarget.innerHTML =
          'The saliva and breath testing devices used with the DynamiCare Health platform provide only qualitative, preliminary results. Click again for full disclaimer.'
      }
    })
  }
}
