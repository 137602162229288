import { Controller } from 'stimulus'
import * as d3 from 'd3'
import { parseISO, format } from 'date-fns'

class TypesController extends Controller {
  public dataValue: { date: string; value: number }[]
}

export default class extends (Controller as typeof TypesController) {
  static values = { data: Array }

  connect() {
    const margin = { top: 10, right: 30, bottom: 30, left: 60 }
    const width = 800 - margin.left - margin.right
    const height = 400 - margin.top - margin.bottom

    const svg = d3
      .select('#d3-target')
      .append('svg')
      .attr('class', 'w-full')
      .attr('viewBox', `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    // X axis
    const x = d3
      .scaleTime()
      .range([0, width])
      .domain([
        Math.min(...this.dataValue.map((d) => parseISO(d.date).getTime())),
        Math.max(...this.dataValue.map((d) => parseISO(d.date).getTime())),
      ])
    svg
      .append('g')
      .style('font', '16px Roboto')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(0))

    // Y axis
    const y = d3.scaleLinear().domain([0, 30]).range([height, 0])
    svg
      .append('g')
      .style('font', '16px Roboto')
      .call(d3.axisLeft(y).tickValues([0, 10, 20, 30]))
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - height / 2)
      .attr('dy', '1em')
      .style('text-anchor', 'middle')
      .text('Streak')

    // Maintain shaded rectangle
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height / 3)
      .attr('stroke', '#000')
      .attr('stroke-width', 1)
      .attr('color', '#fff1cc')
      .attr('class', 'fill-current')
    svg
      .append('text')
      .attr('x', 50)
      .attr('y', height / 6 - 8)
      .attr('dy', '1em')
      .attr('class', 'text-bold')
      .text('Maintain')

    // Build shaded rectangle
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', height / 3)
      .attr('width', width)
      .attr('height', height / 3)
      .attr('stroke', '#000')
      .attr('color', '#fde5cd')
      .attr('class', 'fill-current')
    svg
      .append('text')
      .attr('x', 50)
      .attr('y', (height / 6) * 3 - 8)
      .attr('dy', '1em')
      .attr('class', 'text-bold')
      .text('Build')

    // Anchor shaded rectangle
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', (height / 3) * 2)
      .attr('width', width)
      .attr('height', height / 3)
      .attr('stroke', '#000')
      .attr('color', '#f4cccc')
      .attr('class', 'fill-current')
    svg
      .append('text')
      .attr('x', 50)
      .attr('y', (height / 6) * 5 - 8)
      .attr('dy', '1em')
      .attr('class', 'text-bold')
      .text('Anchor')

    // Line on line graph
    svg
      .append('path')
      .datum(this.dataValue)
      .attr('fill', 'none')
      .attr('stroke', '#000')
      .attr('stroke-width', 3)
      .attr(
        'd',
        d3
          .line<any>()
          .x(function (d: any) {
            return x(parseISO(d.date))
          })
          .y(function (d: any) {
            return y(d.value)
          })
          .curve(d3.curveMonotoneX),
      )

    const tooltip = d3.select('body').append('div').attr('class', 'd3-tooltip')

    const mouseover = function (d: any) {
      tooltip.transition().duration(200).style('opacity', 1)
      tooltip
        .html(`${format(parseISO(d.date), 'MM/dd')}: ${d.value}`)
        .style('left', `${(d3 as any).event.pageX}px`)
        .style('top', `${(d3 as any).event.pageY - 28}px`)
    }

    const mouseout = function (d: any) {
      tooltip.transition().duration(500).style('opacity', 0)
    }

    const selectCircle = svg.selectAll('.circle').data(this.dataValue)
    selectCircle
      .enter()
      .append('circle')
      .attr('class', 'circle')
      .attr('fill', 'rgb(0, 188, 212)')
      .attr('r', 7)
      .attr('opacity', 0.9)
      .attr('cx', (d: any) => x(parseISO(d.date)))
      .attr('cy', (d: any) => y(d.value))
      .on('mouseover', mouseover)
      .on('mouseout', mouseout)
  }
}
