import 'core-js/stable'
import 'regenerator-runtime/runtime'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Rails from '@rails/ujs'
import '../../components/analytics/ActionsTracker'

import 'stylesheets/application.css'
import 'stream-chat-react/dist/css/index.css'
import 'feather-icons/dist/feather-sprite.svg'
import 'stream-chat-react/dist/css/index.css'

Rails.start()
const application = Application.start()
const context = require.context('./../../controllers', true, /\.(js|ts|tsx)$/)
application.load(definitionsFromContext(context))

declare global {
  interface Window {
    initMap: any
  }
}

window.initMap = function (...args: any) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  ;(event as any).args = args
  window.dispatchEvent(event)
}
