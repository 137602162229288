import { Controller } from 'stimulus'

class TypesController extends Controller {
  public phasedFrequencyExpandedParamTargets: [HTMLInputElement]
  public phasedFrequencyExpandedToggleParamTargets: [HTMLInputElement]
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['phasedFrequencyExpandedParam', 'phasedFrequencyExpandedToggleParam']

  enable (element: HTMLElement) {
    element.removeAttribute('disabled')
    element.classList.remove('disabled')

    const parent = element.parentElement as HTMLElement
    const label = parent.querySelector('label') as HTMLElement

    parent.classList.remove('disabled')
    label.classList.remove('disabled')
  }

  disable (element: HTMLElement) {
    element.setAttribute('disabled', 'disabled')
    element.classList.add('disabled')

    const parent = element.parentElement as HTMLElement
    const label = parent.querySelector('label') as HTMLElement

    parent.classList.add('disabled')
    label.classList.add('disabled')
  }

  changed (event: Event) {
    const field = event.target as HTMLInputElement
    if (field.value == 'PhasedFrequencyExpandedRewardService') {
      this.phasedFrequencyExpandedParamTargets.forEach((element) => { this.enable(element) })
      this.phasedFrequencyExpandedToggleParamTargets.forEach((element) => { this.disable(element) })
    } else {
      this.phasedFrequencyExpandedParamTargets.forEach((element) => { this.disable(element) })
      this.phasedFrequencyExpandedToggleParamTargets.forEach((element) => { this.enable(element) })
    }
  }
}
