import { Controller } from 'stimulus'

class TypesController extends Controller {
  public displaysTarget: HTMLElement

  public selectTarget: HTMLInputElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['displays', 'select']

  connect() {
    const startingValue = this.currentValue

    Array.from(this.displaysTarget.children).forEach((element: HTMLElement) => {
      const { valueMapping } = element.dataset

      if (valueMapping === startingValue) {
        element.style.display = 'inherit'
        if (element.tagName === 'FIELDSET') (element as HTMLFieldSetElement).disabled = false
      } else {
        element.style.display = 'none'
        if (element.tagName === 'FIELDSET') (element as HTMLFieldSetElement).disabled = true
      }
    })
  }

  changeValue(event: Event) {
    const { value } = event.target as HTMLSelectElement

    Array.from(this.displaysTarget.children).forEach((element: HTMLElement) => {
      const { valueMapping } = element.dataset

      if (valueMapping === value) {
        element.style.display = 'inherit'
        if (element.tagName === 'FIELDSET') (element as HTMLFieldSetElement).disabled = false
      } else {
        element.style.display = 'none'
        if (element.tagName === 'FIELDSET') (element as HTMLFieldSetElement).disabled = true
      }
    })
  }

  get currentValue() {
    switch (this.selectTarget.type) {
      case 'radio':
        return (document.querySelector(
          `input[type="radio"][name="${this.selectTarget.name}"]:checked`,
        ) as HTMLInputElement).value
      default:
        return this.selectTarget.value
    }
  }
}
