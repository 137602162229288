import axios from 'axios'
import { Controller } from "stimulus"

class TypesController extends Controller {
  public emailLabelTarget: HTMLElement
  public emailFieldTarget: HTMLInputElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['emailLabel', 'emailField']

  change(event: Event) {
    const { value } = event.target as HTMLInputElement

    axios.get(`/programs/${value}/type`).then(res => {

      if (res.data.replace(/\s/g,'') == 'appless') {
        this.emailLabelTarget.removeAttribute('required')
        this.emailFieldTarget.required = false
      } else {
        this.emailLabelTarget.setAttribute('required', 'required')
        this.emailFieldTarget.required = true
      }
    }).catch(err => console.error(err))
  }
}
