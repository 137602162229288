import { Controller } from 'stimulus'
import M from 'materialize-css'
import feather from 'feather-icons'

class TypesController extends Controller {
  public toastTarget: HTMLElement
  public typeValue: string
  public messageValue: string
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['toast']
  static values = { type: String, message: String }

  connect() {
    if (this.messageValue) {
      const content = `
        <div class="grid-two-columns" data-controller="toast" data-action="click->toast#dismiss" data-toast-target="toast">
          <span>
            ${this.messageValue}
          </span>
          <button class='btn-flat toast-action margin-top-0'>
            ${feather.icons['x-circle'].toSvg()}
          </button>
        </div>
      `

      M.toast({
        html: content,
        displayLength: 10000,
        classes: `rounded ${this.typeValue === 'alert' ? 'red' : 'green'}`,
      })
    }
  }

  dismiss() {
    const toast = this.toastTarget.parentElement as HTMLElement

    M.Toast.getInstance(toast).dismiss()
  }
}
