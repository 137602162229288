import axios from 'axios'
import { Controller } from 'stimulus'

class TypesController extends Controller {
  public contentTarget: HTMLElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['content', 'icon']

  change(event: Event) {
    const { value } = event.target as HTMLInputElement

    axios.get(`/programs/${value}/patient_fields`).then(res => {
      this.contentTarget.innerHTML = res.data
    }).catch(err => console.error(err))
  }
}
