import { Controller } from 'stimulus'

class TypesController extends Controller {
  public frequencyTarget: HTMLInputElement;
  public messageTarget: HTMLSelectElement;
  public maximumTarget: HTMLInputElement;
  public minimumTarget: HTMLInputElement;
  public submitTarget: HTMLInputElement;
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['frequency', 'message', 'maximum', 'minimum', 'submit']

  change() {
    const max = parseInt(this.maximumTarget.value)
    const min = parseInt(this.minimumTarget.value)
    const frequency = parseInt(this.frequencyTarget.value)

    if (!frequency || frequency > max || frequency < min) {
      this.messageTarget.innerHTML = `The frequency must be between ${min} and ${max}`
      this.submitTarget.setAttribute('disabled', 'disabled')
    } else {
      this.messageTarget.innerHTML = ''
      this.submitTarget.removeAttribute('disabled')
    }
  }
 }
