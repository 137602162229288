import { Controller } from 'stimulus'

class TypesController extends Controller {
  public displayTarget: HTMLElement

  public inputTarget: HTMLInputElement
}

export default class extends (Controller as typeof TypesController) {
  static targets = ['display', 'input']

  connect() {
    const checked = this.inputTarget.checked
  
    if (this.displayTarget.tagName === 'FIELDSET' && !checked) (this.displayTarget as HTMLFieldSetElement).disabled = true
  }

  changeValue(event: Event) {
    const { checked } = event.target as HTMLInputElement

    if (this.displayTarget.tagName !== 'FIELDSET') return

    this.displayTarget.querySelectorAll<HTMLInputElement>('input[type="checkbox"]').forEach(el => {
      el.checked = false
    });

    (this.displayTarget as HTMLFieldSetElement).disabled = !checked
  }
}
